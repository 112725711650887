<template>
  <v-dialog v-model="show" transition="dialog-top-transition" max-width="400">
    <v-overlay absolute :value="loading">
      <v-progress-circular indeterminate color="#137547"></v-progress-circular>
    </v-overlay>
    <v-card elevation="10" max-height="1000" max-width="400">
      <v-card-title>Prijavi ovaj oglas</v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-textarea
            v-model="reportMessage"
            color="#137547"
            outlined
            label="Tekst prijave"
            placeholder="Unesite razlog zašto mislite da je ovaj oglas neprikladan."
            type="text"
            :rules="textareaRules"
            required
          ></v-textarea>
          <p>{{ errorMessage }}</p>
          <v-row justify="center" dense>
            <v-btn color="error" text class="mr-2" @click="show = false"> Odustani </v-btn>
            <v-btn color="success" @click="submitForm"> Prijavi </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> X </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "ReportProduct",
  data() {
    return {
      hash: this.$route.params.hash,
      reportMessage: "",
      loading: false,
      errorMessage: "",
      textareaRules: [(v) => !!v || "Polje je obavezno"],
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let body = {
          message: this.reportMessage,
        };
        await axios
          .post(`Product/MarkInappropriate/${this.hash}`, body)
          .then(() => {
            this.loading = false;
            this.$emit("productIsReported");
            this.show = false;
          })
          .catch(() => {
            this.loading = false;
            this.errorMessage = "Došlo je do greške. Pokušajte ponovo kasnije.";
            setTimeout(() => (this.errorMessage = ""), 3000);
          });
      }
    },
  },
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
